import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosClient from "../../../api/axiosClient";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe('pk_test_kUlISJkix0GeJ7fpNjidnrdy00DyF24Qmo');

const Payment = (props) => {
  const history = useHistory();
  const orderId = props.match.params.id;

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentType, setPaymentType] = useState("credit_card")
  const onChangeType = (e) => {
    setPaymentType(e.target.value)
  }

  useEffect(() => {
    if (orderId) {
      axiosClient.post(`/line/api/orders/${orderId}/create_payment_intent`).then(response => {
        console.log("resonse", response)
        setClientSecret(response.client_secret)
      })
    }
  }, [orderId])

  return (
    <>
      <header className="line-header bg-green line-header mb-3">
        <div className="title-header lg align-items-center text-center text-white">
          決済入力
        </div>
      </header>
      <div
        className="d-flex flex-column"
        style={{ height: "calc(100vh - 56px)", margin: "0px -15px" }}
      >
        <div className="flex-grow-1 d-flex flex-column p-3">
          <div className="font-weight-bold">支払い方法</div>
          <div className="mt-4">
            <form>
              <label className="radio-inline">
                <input type="radio" name="payment_type" value="credit_card" checked={paymentType === "credit_card"} onChange={onChangeType}/>クレジットカードで事前決済
              </label>
              <div style={{fontSize: 12, color: 'rgb(136 136 136)'}} className="ml-3 mb-2">こちらのお支払いは、triplaによる手配・ク<br />レジットカードによる事前決済となります。</div>
              <label className="radio-inline">
                <input type="radio" name="payment_type" value="cash" checked={paymentType === "cash"} onChange={onChangeType}/>
                現地決済
              </label>
            </form>
          </div>
          {paymentType === "credit_card" ? (
            <>
              <div className="font-weight-bold mt-4">クレジットカード</div>
              <label className="mt-2 radio-inline">
                <input type="radio" checked />
                新しいクレジットカードを入力
              </label>
              {clientSecret && (
                <div className="mt-2">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm clientSecret={clientSecret} />
                  </Elements>
                </div>
              )}
            </>
          ) : (
            <Button
              type='submit'
              onClick={() => history.push({ pathname: "/line/orders/success" })}
              className="bg-danger border-0 mx-auto mt-4 d-block"
            >
              発注
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
